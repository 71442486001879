// @ts-check
import dayjs from "dayjs";

/**
 * Returns tomorrow's date in a string with the format specified
 * @param {string} [dateFormat] (Optional) The format of the date to return. Defaults to "DD-MMM-YY"
 *
 * @returns {string} Tomorrow's date in the specified format
 *
 * @example
 * ```js
 * const tomorrowStringDate = getTomorrowDateString();
 * console.log(tomorrowStringDate); // "12-Dec-21"
 * ```
 */
export default function getTomorrowDateString(dateFormat = "DD-MMM-YY") {
  const tomorrow = dayjs().add(1, "day");
  return tomorrow.format(dateFormat);
}
